<script>
import { DateTime } from 'luxon'
import Config from '@/config'

export default {
  name: 'ViewMessagingDetails',

  data() {
    return {
      loading: false,
      messages: [],
    }
  },

  computed: {
    nft() {
      return this.$messaging.selectedTopic?.nft
    },

    nftId() {
      return this.$route.params.id.split('_')[0]
    },

    tokenId() {
      return this.$route.params.id.split('_')[1]
    }
  },

  methods: {
    async init() {
      try {
        this.loading = true

        let r = await this.$messaging.getMessagesList({
          nftId: this.nftId,
          tokenId: this.tokenId
        })

        this.messages = r.data
      } catch(e) {
        $console.error(e)
        this.error = e
      } finally {
        this.loading = false
      }
    },

    messageExplorer(message) {
      let parts = message.transactionId.split('@')
      return `${Config.hederaExplorer}/transaction/${parts[0]}-${parts[1].replace('.', '-')}`
    },

    messageTime(message) {
      const ts = this.$basil.get(message, 'transactionTimestamp')
      let creation = DateTime.fromSeconds(parseInt(ts))
      let now = DateTime.now()

      if(now.startOf('day').toMillis() === creation.startOf('day').toMillis()) {
        return `${this.$t('conn3ct-wallet.messaging_send_today')} ${creation.toLocaleString(DateTime.TIME_24_SIMPLE)}`
      }

      return creation.toLocaleString({day: 'numeric', month: 'short', hour: 'numeric', minute: '2-digit'})
    }
  },

  mounted() {
    if(this.$messaging.selectedTopic === null) {
      this.$router.push({ name: 'sayl-connect_messaging-messaging' }).catch(() => {})

      return
    }

    this.init()
  },
}
</script>

<template>
  <div class="module-messaging details">
    <header
      v-if="nft"
      class="details__header"
    >
      <div class="details__title">
        <ui-illustration
          :alt="nft.name"
          class="details__illustration"
          fit="contain"
          :src="nft.imageUrl"
          :type="nft.imageType"
        />

        <h2
          v-if="nft.name"
          class="details__name"
        >{{ nft.name }}</h2>
      </div>

      <actions-button
        @click="$router.push({
          name: 'sayl-connect_wallet-nft',
          params: { id: nft.id }
        })"
      >{{ $t('conn3ct-wallet.view_nft') }}</actions-button>
    </header>

    <div class="details__messages">
      <div
        v-for="message in messages"
        class="details__message"
        :key="message.id"
      >
        <ui-panel>
          <template
            v-if="message.subject"
            v-slot:header
          >
            <h3 class="title">{{ message.subject }}</h3>
          </template>

          <div
            v-html="message.message"
            class="ql-editor"
          ></div>
        </ui-panel>

        <div class="details__message-meta">
          <p class="details__message-time">
            <span>{{ messageTime(message) }}</span>
            <span
              v-if="!message.isRead"
              class="details__message-new"
            >{{ $t('conn3ct-wallet.messaging_message_is_new') }}</span>
            <img class="details__pixel" :src="message.pixel" />
          </p>

          <a :href="messageExplorer(message)" target="_blank">{{ $t('conn3ct-wallet.messaging_view_transaction') }}</a>
        </div>
      </div>
    </div>
  </div>
</template>
